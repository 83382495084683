// light theme:
// default
// $text-lightmode: #000000;
// $bg-lightmode: #f0f0f0;
// $gray-lightmode: #757575;

// dark theme:
$text-darkmode: #f0f0f0;
$bg-darkmode: #080808;
$gray-darkmode: #afaeae;

// Links.scss
// $primary: #000000;

// new theme:
// $text-lightmode: #1d1145;
// $bg-lightmode: #ffffff;
// $gray-lightmode: #e76d89;
// $primary: #0db4b9;

// $text-lightmode: #2C1829;
// $bg-lightmode: #fff5d7;
// $gray-lightmode: #feb300;
// $primary: #ff5e6c;

// $text-lightmode: #101357;
// $bg-lightmode: #ffffff;
// $gray-lightmode: #fea49f;
// $primary: #007f4f;

// $text-lightmode: #101357;
// $bg-lightmode: #ffffff;
// $gray-lightmode: #e76d89;
// $primary: #0db4b9;

$text-lightmode: #123c69;
$bg-lightmode: #eee2dc;
$gray-lightmode: #7a7275;
$primary: #ac3b61;

// Work.scss
$accent: $primary;

// All icon.js
:export {
  icon: $primary;
}
