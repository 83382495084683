@import './Colors.module.scss';

#intro-heading {
	font-size: 2.4em;
}

#pronouns {
	font-size: 0.45em;
	color: $gray-lightmode;
}

#intro-content {
	font-size: 1.25em;
	padding-right: 30%;
}

// @media (prefers-color-scheme: dark) {
//     #pronouns {
// 		color: $gray-darkmode;
// 	}
// }
//
// @media (prefers-color-scheme: light) {
//     #pronouns {
// 		color: $gray-lightmode;
// 	}
// }

@media (max-width: 768px) {
	#intro-content {
		padding-right: 25%;
	}

	// when intro is too big to fit in iPhone X
	@media (min-width:321px) {
		#intro {
			margin-top: -10vh;
		}
	}
}
