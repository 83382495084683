#bio {
  font-size: 1em;
}

#portrait {
  display: flex;
  width: 100%;
  height: auto;
  margin: auto;
  padding: 5px;
  border: 1px solid;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  #portrait {
    display: none;
  }
}
