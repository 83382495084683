@import url(https://use.typekit.net/woy4mbd.css);
@import url(https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@800&display=swap);
:export{icon:#ac3b61}html{font-size:14px}body{font-family:stratos-lights, sans-serif !important;font-weight:400 !important;font-style:normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;padding-top:env(safe-area-inset-top);padding-bottom:env(safe-area-inset-bottom);padding-left:env(safe-area-inset-left);padding-right:env(safe-area-inset-right);background-color:#eee2dc}h1,h2,h3{font-family:orpheuspro, serif !important;font-weight:500 !important;font-style:normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.viewport-fill{width:100%;min-height:100vh}.vertical-center{display:flex;align-items:center}@media (min-width: 320px){html{font-size:calc(14px + 4 * (100vw - 320px) / 1046)}}@media (min-width: 1366px){html{font-size:18px}}

:export{icon:#ac3b61}.App{font-weight:400;color:#123c69}.App h1{font-weight:600}a{color:#ac3b61;-webkit-text-decoration-line:none !important;text-decoration-line:none !important}*{margin:0;padding:0;box-sizing:border-box}header{display:flex;padding:10px 5%;background-color:#eee2dc;justify-content:space-between;align-items:center}header a{color:#123c69}#logo{font-family:'Nanum Myeongjo', serif !important;font-weight:800 !important;font-size:2em}.inline ul{margin:0;padding:0;list-style-type:none}.inline li{display:inline-block}nav{text-transform:uppercase}nav li{padding:0 1em}#footer{text-align:center;padding-bottom:1.25em}#footer #mobile-links{display:none;justify-content:center}#footer #mobile-links li{padding:0 0.5em}@media (max-width: 768px){#footer #mobile-links{display:flex}}



:export{icon:#ac3b61}.links{position:fixed;z-index:5;right:3%;bottom:0;align-items:center;padding-top:0;list-style-type:none;list-style-position:outside;list-style-position:initial;list-style-image:none;list-style-image:initial}.links ul{display:flex;flex-direction:column}.links ul li{margin:0.5em 0;list-style:none}.links::after{content:'';display:block;width:2px;height:3em;margin:0 auto 0 0.6em;background-color:#ac3b61}.hvr-grow{display:inline-block;vertical-align:middle;-webkit-transform:translateZ(0);transform:translateZ(0);box-shadow:0 0 1px rgba(0,0,0,0);-webkit-backface-visibility:hidden;backface-visibility:hidden;-moz-osx-font-smoothing:grayscale;-webkit-transform:scale3d(1, 1, 1);transform:scale3d(1, 1, 1);transition-duration:0.5s;transition-property:-webkit-transform;transition-property:transform;transition-property:transform, -webkit-transform}.hvr-grow:hover,.hvr-grow:focus,.hvr-grow:active{-webkit-transform:scale3d(1.5, 1.5, 1.5);transform:scale3d(1.5, 1.5, 1.5)}@media (max-width: 768px){.links{display:none}}

:export{icon:#ac3b61}#intro-heading{font-size:2.4em}#pronouns{font-size:0.45em;color:#7a7275}#intro-content{font-size:1.25em;padding-right:30%}@media (max-width: 768px){#intro-content{padding-right:25%}}@media (max-width: 768px) and (min-width: 321px){#intro{margin-top:-10vh}}

:export{icon:#ac3b61}.company{max-width:100%}.hvr-grow{display:inline-block;vertical-align:middle;-webkit-transform:translateZ(0);transform:translateZ(0);box-shadow:0 0 1px rgba(0,0,0,0);-webkit-backface-visibility:hidden;backface-visibility:hidden;-moz-osx-font-smoothing:grayscale;-webkit-transform:scale3d(1, 1, 1);transform:scale3d(1, 1, 1);transition-duration:0.5s;transition-property:-webkit-transform;transition-property:transform;transition-property:transform, -webkit-transform}.hvr-grow:hover,.hvr-grow:focus,.hvr-grow:active{-webkit-transform:scale3d(1.025, 1.025, 1.025);transform:scale3d(1.025, 1.025, 1.025)}.job-info{text-align:right}.job-info h2{padding-bottom:0.25em;font-weight:600}.job-info h3{display:none;color:#ac3b61}.job-info .MuiGrid-item:nth-last-child(1){display:inline-block;font-size:0.9em;color:#7a7275}@media (max-width: 768px){.company{display:none}.job-info{text-align:left}.job-info h2{padding-bottom:0em}.job-info h3{display:block;padding-bottom:0.25em}}

#bio{font-size:1em}#portrait{display:flex;width:100%;height:auto;margin:auto;padding:5px;border:1px solid;border-radius:50%;justify-content:center;align-items:center}@media (max-width: 768px){#portrait{display:none}}

