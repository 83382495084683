@import './Colors.module.scss';

.company {
  max-width: 100%;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transform: scale3d(1,1,1);
  transition-duration: 0.5s;
  transition-property: transform;

  &:hover, &:focus, &:active {
    transform: scale3d(1.025,1.025,1.025);
  }
}

.job-info {
 text-align: right;

  h2 {
    padding-bottom: 0.25em;
    font-weight: 600;
  }

  h3 {
    display: none;
    color: $accent;
  }

  .MuiGrid-item:nth-last-child(1) {
    display: inline-block;
    font-size: 0.9em;
    color: $gray-lightmode;
  }
}

@media (max-width: 768px) {
  .company {
    display: none;
  }

  .job-info {
    text-align: left;

    h2 {
      padding-bottom: 0em;
    }

    h3 {
      display: block;
      padding-bottom: 0.25em;
    }
  }
}

// @media (prefers-color-scheme: dark) {
//   .job-info {
//     p:nth-last-child(1) {
//       color: $gray-darkmode;
//     }
//   }
// }
//
// @media (prefers-color-scheme: light) {
//   .job-info {
//     p:nth-last-child(1) {
//       color: $gray-lightmode;
//     }
//   }
// }
