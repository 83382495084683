@import url('https://use.typekit.net/woy4mbd.css');
@import './components/Colors.module.scss';

// Base font size property
html {
  font-size: 14px;
}

body {
  font-family: stratos-lights, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  background-color: $bg-lightmode;
}

h1, h2, h3 {
  font-family: orpheuspro, serif !important;
  font-weight: 500 !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Center vertically
.viewport-fill {
  width: 100%;
  min-height: 100vh;
}

.vertical-center {
  display: flex;
  align-items: center;
}

// From: https://www.sassmeister.com/gist/7f22e44ace49b5124eec
@media (min-width: 320px) {
  html {
    font-size: calc(14px + 4 * (100vw - 320px) / 1046);
  }
}

@media (min-width: 1366px) {
  html {
    font-size: 18px;
  }
}
