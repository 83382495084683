@import './Colors.module.scss';

@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@800&display=swap');

.App {
  font-weight: 400;
  color: $text-lightmode;

  h1 {
    font-weight: 600;
  }
}

a {
	color: $accent;
	text-decoration-line: none !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

header {
  display: flex;
  padding: 10px 5%;
  background-color: $bg-lightmode;
  justify-content: space-between;
  align-items: center;

  a {
    color: $text-lightmode;
  }
}

#logo {
  font-family: 'Nanum Myeongjo', serif !important;
  font-weight: 800 !important;
  font-size: 2em;
}

.inline {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    display: inline-block;
  }
}

nav {
  text-transform: uppercase;

  li {
    padding: 0 1em;
  }
}

#footer {
  text-align: center;
  padding-bottom: 1.25em;

  #mobile-links {
    display: none;
    justify-content: center;

    li { padding: 0 0.5em; }
  }
}

@media (max-width: 768px) {
  #footer #mobile-links {
    display: flex;
  }
}

// @media (prefers-color-scheme: dark) {
//   .App {
//     color: $text-darkmode;
//     background-color: $bg-darkmode;
//   }
//
//   a {
//     color: $text-darkmode !important;
//   }
//
//   header {
//     background-color: $bg-darkmode;
//   }
//
//   #footer {
//     #mobile-links {
//       filter: invert(90%) sepia(6%) saturate(492%) hue-rotate(352deg) brightness(95%) contrast(89%);
//     }
//   }
// }
//
// @media (prefers-color-scheme: light) {
//   .App {
//     color: $text-lightmode;
//     background-color: $bg-lightmode;
//   }
//
//   a {
//     color: $text-lightmode !important;
//   }
//
//   header {
//     background-color: $bg-lightmode;
//   }
// }
